<template>
  <div>
    <card>
      <h4>Add Author</h4>
      <form>
        <div class="row mb-3">
          <div class="col">
            <div class="row p-2">
              <div class="col-4">
                <label for="firstname" class="col col-form-label">
                  <b>First Name</b>
                </label>
              </div>
              <div class="col">
                <input type="text" class="form-control" id="firstname" placeholder="First Name" v-model="userdetails.firstname"/>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="lastname" class="col col-form-label">
                  <b>Last Name</b>
                </label>
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="lastname"
                  placeholder="Last Name"
                  v-model="userdetails.lastname"
                />
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="email" class="col col-form-label"
                  ><b>Email</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Email"
                  v-model="userdetails.email"
                />
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="contactprimary" class="col col-form-label"
                  ><b>Contact Number(Primary)</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="contactnumberprimary"
                  placeholder="Primary Contact Number"
                  v-model="userdetails.contactprimary"
                />
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="contactsecondary" class="col col-form-label"
                  ><b>Contact Number(Secondary)</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="contactsecondary"
                  placeholder="Alternative Contact Number"
                  v-model="userdetails.contactsecondary"
                />
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="addressline1" class="col col-form-label"
                  ><b>Address Line 1</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="addressline1"
                  placeholder="Address Line 1"
                  v-model="userdetails.addressline1"
                />
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="addressline2" class="col col-form-label"
                  ><b>Address Line 2</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="addressline2"
                  placeholder="Address Line 2"
                  v-model="userdetails.addressline2"
                />
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="city" class="col col-form-label"
                  ><b>City</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  placeholder="City"
                  v-model="userdetails.city"
                />
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="state" class="col col-form-label"
                  ><b>State</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  placeholder="State"
                  v-model="userdetails.state"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row p-2">
              <div class="col-4">
                <label for="country" class="col col-form-label"
                  ><b>Country</b></label
                >
              </div>
              <div class="col">
                <select id="country" name="country" class="form-control" v-model="userdetails.country">
                  <option value="" disabled selected hidden>
                    Select Country
                  </option>
                  <option v-for="country in countriesList" :value="country.name">{{ country.name }}</option>
                </select>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="pincode" class="col col-form-label"
                  ><b>ZIP/Postal Code</b></label
                >
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="pincode"
                  placeholder="Zip/Postal Code"
                  v-model="userdetails.pincode"
                />
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="currency" class="col col-form-label"
                  ><b>Currency</b></label
                >
              </div>
              <div class="col">
                <select id="currency" name="country" class="form-control" v-model="userdetails.currency">
                  <option value="inr" disabled selected hidden>INR</option>
                  <option v-for="country in countriesList" :value="country.currency.code">{{ country.currency.code }}</option>
                </select>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="username" class="col col-form-label"
                  ><b>Username</b></label
                >
              </div>
              <div class="col">
                <input type="text" class="form-control" id="username" placeholder="Username" v-model="userdetails.username"/>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="password" class="col col-form-label"
                  ><b>Password</b></label
                >
              </div>
              <div class="col">
                <ValidationProvider
                  name="password"
                  rules="required|confirmed:confirmation"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    v-model="userdetails.password"
                    type="password"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-4">
                <label for="confirm" class="col col-form-label"
                  ><b>Confirm Password</b></label
                >
              </div>
              <div class="col">
                <ValidationProvider
                  name="confirm"
                  vid="confirmation"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <base-input
                    required
                    v-model="userdetails.pwconfirmation"
                    type="password"
                    :error="errors[0]"
                    :class="[
                      { 'has-success': passed },
                      { 'has-danger': failed },
                    ]"
                  >
                  </base-input>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="row p-2 offset-4">
          <div class="col">
            <label for="welcome"><b>Welcome Mail : </b></label> &nbsp;

            <el-checkbox id="welcome" v-model="userdetails.sendwelcomemail">Yes</el-checkbox>
            <span class="text-primary mail">
              <i> &nbsp; Select Yes to send author Signup mail</i></span
            >
          </div>
        </div>
        <div class="row p-5">
          <div class="col offset-2">
              <base-button type="info" @click="cancelAuthor">Cancel</base-button>
          </div>
          <div class="col offset-2">
            <router-link to="authors">
              <base-button type="info" @click="saveUserData">Save</base-button>
            </router-link>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>
<script>
import countries from '../../util/countries.js';
import { Select, Switch, Checkbox } from "element-ui";
import { extend } from "vee-validate";
import { required, confirmed } from "vee-validate/dist/rules";
extend("required", required);
extend("confirmed", confirmed);
export default {
  components: {
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      userdetails: {},
      countriesList: countries.countries,
      selects: {
        simple: "",
        checked: true,
      },
    };
  },
  methods: {
    saveUserData: function() {
      console.log(this.userdetails)
      this.userdetails.userJournal = store.state.journalId
      this.userdetails.userType = 'author'
      this.api.postDataModule('user', this.userdetails, 'accounts/v1/').then((res) => {
        console.log(res.data.status)
      }, (err) => {
        console.log(err)
      })
    },
    cancelAuthor() {
      this.$router.push({path: 'authors'})
    }
   
    
  }
};
</script>
<style scoped>
.mail {
  font-size: 12px;
}
button {
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  border: none;
}
</style>
