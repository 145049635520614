<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Add Indexing">
        <h4 slot="header" class="card-title">Add Indexing</h4>
        <form class="col-md-6">
          <div>
            <base-input
              label="Indexing Name"
              type="text"
              placeholder="Enter Indexing Name"
              v-model="modelData.name"
            >
            </base-input>
            <base-input label="Indexing URL" type="text" placeholder="Indexing URL" v-model="modelData.urlData">
            </base-input>
            <div class="col-md-4 col-sm-4">
              <h4 class="card-title">Indexing Image</h4>
              <image-upload @change="onImageChange" select-text="Select Image" />
            </div>
            <base-button class="mt-3" type="danger" @click="cancelAdd">Cancel</base-button>
            <base-button class="mt-3" type="primary" @click="addNewIndexing">Submit</base-button>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import {
  BaseSwitch,
  ImageUpload,
} from 'src/components/index';

export default {
  components: {
    BaseSwitch,
    ImageUpload
  },
  data() {
    return {
      modelData: {
        logoURL: null
      }
    };
  },
  methods: {
    cancelAdd() {
      this.$router.push({path: 'indexing'})
    },
    onImageChange(file) {
      this.modelData.logoURL = file
    },
    addNewIndexing() {
      let formData = new FormData()
      let fileType = this.getFileType()
      formData.append('logoURL', this.modelData.logoURL)
      formData.append('fileType', fileType)
      this.api.uploadFile('indexing/'+store.state.journalId+'/indexingimage', formData, 'journals/v1/').then((res) => {
        this.modelData.journalId = store.state.journalId
        this.modelData.imageId = res.data.status
        this.modelData.fileType = fileType
        this.createNewIndexing()
      }, (err) => {
        console.log(err)
      })
    },
    createNewIndexing() {
      console.log(this.modelData)
      this.api.postDataModule('indexing', this.modelData, 'journals/v1/').then((res) => {
        console.log(res.data.status)
      }, (err) => {
        console.log(err)
      })
    },
    getFileType: function() {
        if(this.modelData.logoURL.name.endsWith('png')){
          return 'png'
        } else if(this.modelData.logoURL.name.endsWith('jpg')){
          return 'jpg'
        } else if(this.modelData.logoURL.name.endsWith('jpeg')){
          return 'jpeg'
        }
    },
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}
</style>
