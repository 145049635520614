<template>
    <div>
<card card-body-classes="table-full-width">
          <div class="row">
        <div class="col my-2" slot="header"><h3 class="card-title">Editorial Board</h3>
        </div>
        <div class="col offset-9 my-2">
          <base-button type="info" class="btn-sm float-right" @click="addNewAuthor">Add</base-button>
        </div>
          <div class="col-12">
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="45" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="editMember(props.$index, props.row)"
                    class="edit btn-link"
                    type="info"
                    size="md"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="deleteMember(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="md"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
        </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import Buttons from '../Components/Buttons.vue';

export default {
  components: {
    Buttons,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
 },
  data() {
    return {
      membersList: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['paper_title'],
      searchedData: [],
      fuseSearch: null,
      tableColumns: [
        {
          prop: 'member_name',
          label: 'Name',
          minWidth: 135,
        },
        {
          prop: 'member_email',
          label: 'Email',
          minWidth: 85,
        },
        {
          prop: 'member_role',
          label: 'Role',
          minWidth: 85,
        },
        {
          prop: 'member_designation',
          label: 'Designation',
          minWidth: 65,
        },
        {
          prop: 'member_country',
          label: 'Country',
          minWidth: 55,
        },
      ]
    }
  },
  computed: {
    queriedData() {
      let result = this.membersList;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.membersList.length;
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.membersList;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  },
  methods: {
    addNewAuthor() {
        this.$router.push({path: 'add-editorial'})
    },
    getMemberData() {
      this.api.getData('editorial/'+store.state.journalId).then((res)=>{
        this.membersList = res.data.membersList
      },(error)=>{
        console.log(error)
      })
    },
    deleteMember(index, row) {
      console.log(row)
      let memberDetails = {
        member_id: row.member_id
      }
      this.api.putDataModule('editorial/'+store.state.journalId+'/deletemember', memberDetails, 'journals/v1/').then((res) => {
        this.getMemberData()
        swal.fire({
            title: 'Deleted!',
            text: `Member deleted`,
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
      }, (err) => {
        console.log(err)
      })
    },
    editMember(index, row) {
      console.log(row)
    }
  },
  mounted() {
    this.getMemberData()
    this.fuseSearch = new Fuse(this.membersList, {
      keys: ['paper_title'],
      threshold: 0.3
    });
  }

}
</script>

<style>

</style>
