<template>
    <div class="col-12">
    <card card-body-classes="table-full-width">
      <div class="row mb-2">
        <div class="col my-2" slot="header"><h3 class="card-title archive-title">Archives</h3>
    </div>
    <div class="col offset-9 my-2">
        <router-link to="AddArchives">
          <base-button type="info" class="btn-sm float-right">Add</base-button>
        </router-link>
    </div>
          <div class="col-12">
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="65" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="viewPaper(props.$index, props.row)"
                    class="like btn-link"
                    type="info"
                    size="md"
                    icon
                  >
                    <i class="tim-icons icon-paper"></i>
                  </base-button>
                  <base-button
                    @click.native="editPaper(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="md"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="deletePaper(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="md"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
        </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import Buttons from '../Components/Buttons.vue';

export default {
  components: {
    Buttons,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data: function() {
    return {
      papersList: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['paper_title'],
      searchedData: [],
      fuseSearch: null,
      tableColumns: [
        {
          prop: 'year',
          label: 'Year',
          minWidth: 35,
        },
        {
          prop: 'volume',
          label: 'Volume',
          minWidth: 35,
        },
        {
          prop: 'issue',
          label: 'Issue',
          minWidth: 35,
        },
        {
          prop: 'paper_title',
          label: 'Title',
          minWidth: 235,
        },
        {
          prop: 'paper_author',
          label: 'Author',
          minWidth: 135,
        },
        {
          prop: 'paper_pages',
          label: 'Pages',
          minWidth: 35,
        }
      ]
    }
  },
  computed: {
    queriedData() {
      let result = this.papersList;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.papersList.length;
    }
  },
  methods: {
    getPapersList: function() {
      this.api.getDataModule('archives/'+store.state.journalId+'/getjournalpaperslist', 'journals/v1/').then((res) => {
        this.papersList = res.data.papersList
      }, (err) => {
        console.log(err)
      })
    },
    viewPaper: function(index, row) {
      window.open(row.paper_url, '_blank')
    },
    editPaper: function(index, row) {
      let cpId = row.paper_id
      this.$router.push({path: 'EditArchive', query: {paperid: cpId}})
    },
    deletePaper: function(index, row) {
      let paperDetails = {
        paper_id: row.paper_id
      }
      this.api.putDataModule('archives/'+store.state.journalId+'/deletepaper', paperDetails, 'journals/v1/').then((res) => {
        this.getPapersList()
         swal.fire({
            title: 'Deleted!',
            text: `Paper deleted`,
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
      }, (err) => {
        console.log(err)
      })
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.papersList;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  },
  mounted: function() {
    this.getPapersList()
     this.fuseSearch = new Fuse(this.papersList, {
      keys: ['paper_title'],
      threshold: 0.3
    });
  }
}
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}

</style>
